import {
  getSessionDisclaimerKey,
  setSessionDisclaimerKey,
} from '@/utils/sessionDisclaimerKey';
import { useRouter } from 'next/router';
import React from 'react';
import { useState } from 'react';
import Button from '../AtomicComponents/Button';
import Modal from '../../Common/Modal';
import {
  DisclaimerWidgetCheckbox,
  DisclaimerWidgetCheckboxContainer,
  DisclaimerWidgetContainer,
  DisclaimerWidgetCtaCheckbox,
  DisclaimerWidgetErrorText,
  DisclaimerWidgetHeaderTextContainer,
  DisclaimerWidgetHeading,
  DisclaimerWidgetText,
} from './DisclaimerWidget.styles';

function DisclaimerWidget(props) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(false);
  const router = useRouter();

  function renderErrorMessage() {
    return (
      <DisclaimerWidgetErrorText showErrorMessage={showErrorMessage}>
        Please Accept the above
      </DisclaimerWidgetErrorText>
    );
  }

  function handleProceed() {
    const checkBox = document.getElementById('DisclaimerWidgetCheckbox');
    if (checkBox.checked === true) {
      setSessionDisclaimerKey(true);
      setTriggerRerender(!triggerRerender);
    } else {
      setShowErrorMessage(true);
    }
  }

  function handleCheckbox() {
    const checkBox = document.getElementById('DisclaimerWidgetCheckbox');
    if (checkBox.checked === true) {
      setShowErrorMessage(false);
    }
  }

  function isVisible() {
    if (router.query?.theme_page === 'true') return false;
    else return !getSessionDisclaimerKey();
  }

  return (
    <Modal
      visible={isVisible()}
      className="bottom-modal"
      animation={'center'}
      height={200}
    >
      <DisclaimerWidgetContainer>
        <DisclaimerWidgetHeaderTextContainer>
          <DisclaimerWidgetHeading>{props.config?.heading}</DisclaimerWidgetHeading>
          <DisclaimerWidgetText>{props.config?.body_text}</DisclaimerWidgetText>
        </DisclaimerWidgetHeaderTextContainer>
        <DisclaimerWidgetCtaCheckbox>
          <DisclaimerWidgetCheckboxContainer>
            <DisclaimerWidgetCheckbox
              type="checkbox"
              id="DisclaimerWidgetCheckbox"
              onClick={handleCheckbox}
            />
            &nbsp;&nbsp;I agree to the following condition
          </DisclaimerWidgetCheckboxContainer>
          {renderErrorMessage()}
          <Button
            label={props.config?.button_text}
            px="20px"
            py="10px"
            hoverEnabled={true}
            fontColor="#008FFF"
            backgroundColor="#FFFFFF"
            borderColor="#000000"
            onClick={handleProceed}
          />
        </DisclaimerWidgetCtaCheckbox>
      </DisclaimerWidgetContainer>
    </Modal>
  );
}

export default DisclaimerWidget;
