import styled from 'styled-components';

export const DisclaimerWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
`;

export const DisclaimerWidgetHeading = styled.div`
  display: flex;
  font-size: 18px;
  color: #008fff;
  margin-bottom: 40px;
`;

export const DisclaimerWidgetText = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
  white-space: pre-line;
  overflow-y: auto;
  max-height: 60vh;
  @media screen and (max-width: 767px) {
    overflow-y: auto;
    max-height: 50vh;
  }
`;

export const DisclaimerWidgetCheckbox = styled.input`
  display : flex;
  width: 20px;
  height: 20px;
}
`;

export const DisclaimerWidgetCheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  align-items: center;
`;

export const DisclaimerWidgetCtaCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const DisclaimerWidgetHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisclaimerWidgetErrorText = styled.div`
  display: flex;
  color: red;
  margin-bottom: 10px;
  font-size: 12px;
  visibility: ${props => (props.showErrorMessage ? 'visible' : 'hidden')};
`;
