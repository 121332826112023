import { IS_SERVER } from './checkRenderEnv';
import { SESSION_STORAGE_KEY_NAMES } from './constants';

export function setSessionDisclaimerKey(value) {
  if (!IS_SERVER) sessionStorage.setItem(SESSION_STORAGE_KEY_NAMES.DISCLAIMER, value);
}

export function getSessionDisclaimerKey() {
  if (!IS_SERVER) {
    if (sessionStorage?.getItem(SESSION_STORAGE_KEY_NAMES.DISCLAIMER))
      return sessionStorage?.getItem(SESSION_STORAGE_KEY_NAMES.DISCLAIMER);
    return false;
  }
}
